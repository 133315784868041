import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainBoardComponent } from './main-board/main-board.component';
import { GalleryComponent } from './gallery/gallery.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(x => x.LoginModule)
  },
  {
    path: 'mainboard',
    component: MainBoardComponent,
    children: [
      {
        path: 'gallery',
        loadChildren: () => import('./gallery/gallery.module').then(x => x.GalleryModule),
      },
      {
        path: 'analysis',
        loadChildren: () => import('./job-status/job-status.module').then(x => x.JobStatusModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(x => x.DashboardModule)
      }, {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(x => x.UsersModule)
      }, {
        path: 'configure',
        loadChildren: () => import('./configuration/configuration.module').then(x => x.ConfigurationModule)
      }, {
        path: 'upload',
        loadChildren: () => import('./upload-list/upload-list.module').then(x => x.UploadListModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
