<div class="headerMainDiv">
    <div class="airaHeaderLogo">
        <img [src]="'../../'+appConfig.appBaseName+'assets/images/common/am_logo.png'" alt="Aira Logo"
            aria-label="Aira Logo" class="airaLogo" />
        <img [src]="'../../'+appConfig.appBaseName+'assets/images/common/AIRAQC.png'" alt="Aira Logo"
            aria-label="Aira Logo" class="airaLogoText" />
    </div>
    <div class="rightSideHeader">
        <div class="zoomWarningDiv paddingRight" *ngIf="isBroswerZoomed || systemZoomed">
            <!-- <mat-icon title="Please reset browser zoom level to 100% for better UI experience">warning</mat-icon> -->
            <!-- <mat-icon [title]="zoomWarningMsg">warning</mat-icon> -->

        </div>
        <!-- <div class="notigficationDiv">
            <button mat-icon-button [disabled]="!notifications.length" [matMenuTriggerFor]="menu">
                <mat-icon matBadge="{{notifications.length}}" matBadgeColor="warn" class="iconimg"
                    svgIcon="notification"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div class="headerDiv">
                    <div class="headerTitle">Notifications</div>
                    <button mat-button class="btnClearAll" color="warn">Clear All</button>
                </div>
                <div class="notificationScrollDiv">
                    <div class="notificationsDiv" *ngFor="let notification of notifications">
                        <div class="clearIndvdiv">
                            <mat-icon class="clearIndvNotification cursorPointer">clear</mat-icon>
                        </div>
                        <div class="notificationMsg">{{notification.message}}</div>
                        <div class="notify_time">{{notification.createdAt}}</div>
                    </div>
                </div>
            </mat-menu>
        </div> -->
        <div class="selectRole">
            <mat-form-field appearance="fill" disableRipple>
                <mat-select [(ngModel)]="selectedUserRole " (selectionChange)="changeRole()">
                    <mat-option class="headerOptions" *ngFor="let indvUserRole of userRoleArr"
                        [value]="indvUserRole.role">
                        {{indvUserRole.roleDisplayText | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="circle" [ngStyle]="{'background-color': circleColor}">
            <div class="initials">{{initials}}</div>
        </div>
        <div class="usernm_header"> Hello {{firstName}} {{lastName}} </div>
        <img [src]='"../../"+appConfig.appBaseName+"assets/images/svg/logoutIcon.svg"' class="logouticon"
            [matMenuTriggerFor]="beforeMenu" (menuOpened)="showDashboardSave()" />
        <mat-menu #beforeMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="changePassword()">Change Password</button>
            <button mat-menu-item (click)="logout()">Logout</button>
        </mat-menu>
    </div>
</div>