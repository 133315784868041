import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UserService } from 'src/app/services/user.service';
import { ConfigureService } from 'src/app/configuration/configure.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {
  showData: any;
  waitForResponse: boolean = false;
  constructor(@Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService,
    private configService: ConfigureService, public commonService: CommonService) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.showData = {
      'headerTitle': this.data.headerTitle ? this.data.headerTitle : 'Title',
      'confirmMsg': this.data.confirmMsg ? this.data.confirmMsg : 'Are You Sure You want to delete this?',
      'cancelButtonText': this.data.cancelButtonText ? this.data.cancelButtonText : 'Cancel',
      'confirmButtonText': this.data.confirmButtonText ? this.data.confirmButtonText : 'Ok',
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }

  confirmSuccess() {
    if (this.data.confirmActionData.currentPage == "header") {
      this.data.confirmActionData.logoutAction(this.dialogRef, this.data.confirmActionData.that);
    } else if (this.data.confirmActionData.currentPage == "userListing") {
      this.waitForResponse = true;
      this.userService.editUserState(this.data.confirmActionData.source, this.data.confirmActionData.userEmailId).subscribe((result: any) => {
        if (result) {
          this.waitForResponse = false;
          //add toastMessage service call
          this.dialogRef.close('yes');
        }
      })
    }
    else if (this.data.confirmActionData.currentPage == "LocationConfiguration") {
      this.waitForResponse = true;
      this.configService.deleteLocation(this.data.confirmActionData.locationId).subscribe((result: any) => {
        if (result.success) {
          this.waitForResponse = false;
          this.commonService.showToastMsg(result.message, 'success');
          this.waitForResponse = false;
          this.dialogRef.close('yes');
        }
      }, (error: any) => {
        this.dialogRef.close('yes');
        this.commonService.showToastMsg(error.error.message, 'error');
      });
    } else if (this.data.confirmActionData.currentPage == "thresholdConfiguration") {
      this.dialogRef.close('yes');
    }
  }

}
