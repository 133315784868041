import { Component, HostListener, OnInit } from '@angular/core';
import { CommonService } from '../common.service';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-main-board',
  templateUrl: './main-board.component.html',
  styleUrls: ['./main-board.component.less']
})
export class MainBoardComponent implements OnInit {
  appConfig: any = {};
  isBroswerZoomed: boolean = false;
  copyrightMessage: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isBroswerZoomed = this.commonService.checkBrowserZoom();
  }

  constructor(private commonService: CommonService, configService: AppConfigService) {
    this.appConfig = configService.config;
  }

  ngOnInit(): void {
    this.copyrightMessage = this.appConfig.copyrightMessage;
    this.isBroswerZoomed = this.commonService.checkBrowserZoom();
  }

}
