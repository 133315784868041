import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UploadListRoutingModule } from './upload-list-routing.module';
import { UploadListComponent } from './upload-list.component';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../app-material/app-material.module';
import { SharedModule } from "../shared.module";
import { CustomDatePipe } from '../common/custom.datepipe';


@NgModule({
    declarations: [UploadListComponent],
    providers: [
      DatePipe,
      CustomDatePipe
  ],
    imports: [
        CommonModule,
        UploadListRoutingModule,
        RouterModule,
        AppMaterialModule,
        SharedModule,
        
    ]
})
export class UploadListModule { }
