<div class="mainContainer">
  <button mat-flat-button color="primary" class="confirmBtn cursorPointer btnUploadImages" (click)="openUploadDialog()">
    <mat-icon>cloud_upload</mat-icon> <span>Upload</span>
  </button>

  <ng-container *ngIf="uploadList?.length">
    <div class="uploadedImgListingBody">
      <table mat-table [dataSource]="uploadList" class="mat-elevation-z8 customTableDesign">
        <ng-container [matColumnDef]="column.key" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef>{{column.title}} </th>
          <td mat-cell *matCellDef="let element"> {{(column.key=='dateScanned') ?
            (element[column.key] | customDate) : element[column.key] }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
      </table>
    </div>
  </ng-container>

  <ng-container *ngIf="!uploadList?.length">
    <div class="noDataAvailableDiv">
      <img [src]='"../../"+appConfig.appBaseName+"assets/images/common/no_data_final.png"' />
    </div>
  </ng-container>
</div>