<div class="confirmDialogDiv">
  <div class="modal-header dialog-header">
    <div class="headerTitle">
      <div class="dialog-title">{{showData.headerTitle}}</div>
    </div>
    <div class="headerIcon float-right" *ngIf="!waitForResponse">
      <!-- <i class="fa fa-times" (click)="closeDialog()"></i> -->
      <mat-icon class="cursorPointer iconClose" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>
  <div class="container dialog-container modal-body">
    <div class="dispText">{{showData.confirmMsg}}</div>
  </div>
  <div class="actions dialog-actions modal-footer">
    <button mat-flat-button class="cancelBtn cursorPointer"
      (click)="closeDialog()">{{showData.cancelButtonText}}</button>
    <button mat-flat-button *ngIf="!waitForResponse" color="primary" class="btn-default confirmBtn cursorPointer"
      (click)="confirmSuccess()" cdkFocusInitial>{{showData.confirmButtonText}}</button>
    <button mat-flat-button *ngIf="waitForResponse" color="primary" class="btn-default confirmBtn cursorPointer"
      cdkFocusInitial>Saving</button>
  </div>
</div>