<div class="sidebar menuimg_sidebar">
    <ng-container *ngFor="let place of places">
        <a *ngIf="place.isVisible">
            <div class="{{place.moduleName}} indvPlaces" (click)="changePlace(place.moduleName)"
                [ngClass]="[((selectedPlace == place.moduleName)) ? 'activeIcon' : 'defaultIcon']">
                <mat-icon class="iconimg" svgIcon="{{place.moduleName}}Icon" [attr.modulename]="place.moduleName">
                </mat-icon>
                <div class="moduleDispName">{{place.displayName}}</div>
            </div>
        </a>
    </ng-container>
</div>