import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { AppMaterialModule } from './app-material/app-material.module';
import { AppConfigService } from './app-config.service';
import { IconService } from './icons-setting/Icons.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MainBoardModule } from './main-board/main-board.module';
import { UsersComponent } from './users/users.component';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { CustomSnackbarComponent } from './common/custom-snackbar/custom-snackbar.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IndvFolderComponent } from './indv-folder/indv-folder.component';
import { FolderDetailDialogComponent } from './folder-detail-dialog/folder-detail-dialog.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from './shared.module';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadListModule } from './upload-list/upload-list.module';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';


export const appInitializerFn = (configService: AppConfigService) => {
  return () => configService.loadAppConfig();
};
@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    ConfirmDialogComponent,
    ConfigurationComponent,
    CustomSnackbarComponent,
    IndvFolderComponent,
    FolderDetailDialogComponent,
    UploadDialogComponent,
  ],

  imports: [
    SharedModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    MainBoardModule,
    UploadListModule,
    LazyLoadImageModule,
    MatButtonToggleModule,
    ScrollingModule,
    NgxDropzoneModule,
    BackButtonDisableModule.forRoot({
      preserveScroll: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    IconService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader { return new TranslateHttpLoader(http); }