import { HttpClient, HttpBackend, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import * as appConfigData from '../assets/appConfig/appConfig.json';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  config: any;

  constructor(private http: HttpBackend) { }

  loadAppConfig() {
    // this.config = appConfigData;
    return new HttpClient(this.http)
    .get('./assets/appConfig/appConfig.json')
          .toPromise()
          .then(config => {
            this.config = config;
          });
  }

}
