import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private config: any;
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly SESSION_ID = 'SESSION_ID';
  private readonly VIEWER_SLIDES = 'VIEWER_SLIDES';
  private readonly URL_PARENT = 'URL_PARENT';
  private readonly MRN_ID = 'MRN_ID';
  private readonly MRN_STATUS = 'MRN_STATUS';
  private readonly MRN_SPECIMEN = 'MRN_SPECIMEN';
  private readonly PARENT_PROJECT = 'PARENT_PROJECT';
  private readonly PARENT_INFO = 'PARENT_INFO';
  private readonly LOGIN_URL = 'LOGIN_URL';
  private readonly DEFAULT_IMAGE_PREVIEW = 'DEFAULT_IMAGE_PREVIEW';
  private readonly LAST_PARENT_APP_URL = 'LAST_PARENT_APP_URL';
  private readonly VIEWER_BASE_NAME = 'VIEWER_BASE_NAME';
  private readonly CURR_USER = 'CURR_USER';

  private loggedUser: string = '';

  constructor(private http: HttpClient, private cookieService: CookieService, private router: Router, private configService: AppConfigService, private dialog: MatDialog,
    private commonService: CommonService,) {
    this.config = configService.config;
  }

  login(user: { email: string, password: string }): Observable<string> {
    return this.http.post<any>(`${this.config.apiUrl}user/login`, user/*dataObj*/)
      .pipe(
        tap(tokens => {
          this.storeTokens(tokens);
          this.storeLoginData(tokens);
        }),
        mapTo("true"),
        catchError((error: any) => {
          if ((error.error.status == 409) && (error.error.error == "Conflict")) {
            return of('resetPassword');
          } else {
            return of(error.error.status == 401 ? "invalid" : "valid");
          }
        }));
    // catchError(error => {
    //   return of(error.error?.status == 401 ? "invalid" : "valid");
    // }));
  }

  logout() {
    let logoutData = {
      "refreshToken": this.getRefreshToken(),
      "sessionId": this.getSessionId()
    }
    return this.http.post<any>(`${this.config.apiUrl}user/logout`, logoutData).pipe(
      tap(() => {
        this.doLogoutUser()
      }),
      mapTo(true),
      catchError(error => {
        this.doLogoutUser()
        //alert(error.error);
        return of(false);
      }));
  }

  logoutAPICall() {
    let logoutData = {
      "refreshToken": this.getRefreshToken(),
      "sessionId": this.getSessionId()
    }
    return this.http.post<any>(`${this.config.apiUrl}user/logout`, logoutData);
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  refreshToken() {
    this.storeAccessToken('');
    const requestData = {
      'refreshToken': this.getRefreshToken(),
      'sessionId': this.getSessionId()
    }
    return this.http.post(`${this.config.apiUrl}user/token/refresh`, requestData)
      .pipe(tap((tokens: any) => {
        this.storeTokens(tokens);
      }));
  }

  getAccessToken() {
    return this.cookieService.get(this.ACCESS_TOKEN);
  }

  getSessionId() {
    return this.cookieService.get(this.SESSION_ID);
  }

  doLogoutUser() {
    this.removeTokens();
    this.clearLocalStorage();
    this.closeAllOpenDialog();
    this.commonService.sendDashboardSaveEvent(false);
    this.commonService.dashboardUserPrefChanged = false;
  }

  public storeCurrentUser(userInfo: any) {
    this.cookieService.set(this.CURR_USER, JSON.stringify(userInfo), 0, '/');
  }

  closeAllOpenDialog() {
    if (this.dialog.openDialogs.length > 0) {
      this.dialog.closeAll();
      return false;
    }
    return true;
  }

  getRefreshToken() {
    return this.cookieService.get(this.REFRESH_TOKEN);
  }

  resetNewUserPassword(userData: any) {
    return this.http.post(`${this.config.apiUrl}user/resetPassword`, userData)
  }

  getMRNStatus() {
    return this.cookieService.get(this.MRN_STATUS);
  }

  public storeApiUrl(apiUrl: string) {
    this.cookieService.set(this.LOGIN_URL, apiUrl, 0, '/');
  }

  private storeAccessToken(access: string) {
    this.cookieService.set(this.ACCESS_TOKEN, access, 0, '/');
  }

  private storeSessionId(sessionId: string) {
    this.cookieService.set(this.SESSION_ID, sessionId, 0, '/');
  }

  public storeViewerSlides(slidesArr: string) {
    this.cookieService.set(this.VIEWER_SLIDES, slidesArr, 0, '/');
  }

  public storeParentInfo(parentInfo: any) {
    this.cookieService.set(this.PARENT_INFO, parentInfo, 0, '/');
  }

  public storeMRNID(mrnId: any) {
    this.cookieService.set(this.MRN_ID, mrnId, 0, '/');
  }

  public CurrentUser(currUser: any) {
    this.cookieService.set(this.CURR_USER, currUser, 0, '/');
  }

  public storeMRNStatus(mrnStatus: any) {
    this.cookieService.set(this.MRN_STATUS, mrnStatus, 0, '/');
  }

  public storeMRNSpecimen(mrnStatus: any) {
    this.cookieService.set(this.MRN_SPECIMEN, mrnStatus, 0, '/');
  }

  public storeParentProject(ParentProject: any) {
    this.cookieService.set(this.PARENT_PROJECT, ParentProject, 0, '/');
  }

  public storeLastParentAppUrl(parentAppUrl: any) {
    this.cookieService.set(this.LAST_PARENT_APP_URL, parentAppUrl, 0, '/');
  }

  public storeUrlApi(urlParent: any) {
    this.cookieService.set(this.URL_PARENT, urlParent, 0, '/');
  }


  public storeDefaultImgPreview(defaultImagePreview: any) {
    this.cookieService.set(this.DEFAULT_IMAGE_PREVIEW, defaultImagePreview, 0, '/');
  }
  public storeWebViewerBaseName(baseName: any) {
    this.cookieService.set(this.VIEWER_BASE_NAME, baseName, 0, '/');
  }

  private storeTokens(tokens: any) {
    this.cookieService.set(this.ACCESS_TOKEN, tokens.accessToken, 0, '/');
    this.cookieService.set(this.REFRESH_TOKEN, tokens.refreshToken, 0, '/');
    this.cookieService.set(this.SESSION_ID, tokens.sessionId, 0, '/');
  }

  private storeLoginData(resp: any) {
    // localStorage['isfirstLogin'] = resp.isFirstTimeLogin ? resp.isFirstTimeLogin : false;
    this.commonService.ProjectLSData.isfirstLogin = resp.isFirstTimeLogin ? resp.isFirstTimeLogin : false;
    // localStorage['isSessionReplaced'] = resp.sessionReplaced ? resp.sessionReplaced : false;
    this.commonService.ProjectLSData.isSessionReplaced = resp.sessionReplaced ? resp.sessionReplaced : false;
    // localStorage['chooseSession'] = resp.chooseSession ? resp.chooseSession : false;
    this.commonService.ProjectLSData.chooseSession = resp.chooseSession ? resp.chooseSession : false;
  }

  removeTokens() {
    // return;
    this.cookieService.delete(this.ACCESS_TOKEN, '/');
    this.cookieService.delete(this.REFRESH_TOKEN, '/');
    this.cookieService.delete(this.SESSION_ID, '/');
    this.cookieService.delete(this.VIEWER_SLIDES, '/');
    this.cookieService.delete(this.URL_PARENT, '/');
    this.cookieService.delete(this.MRN_ID, '/');
    this.cookieService.delete(this.MRN_STATUS, '/');
    this.cookieService.delete(this.PARENT_PROJECT, '/');
    this.cookieService.delete(this.LAST_PARENT_APP_URL, '/');
    this.cookieService.delete(this.LOGIN_URL, '/');
    // this.cookieService.delete(this.PARENT_INFO, '/');
    // this.cookieService.delete(this.CURR_USER, '/');
    // this.cookieService.delete(this.DEFAULT_IMAGE_PREVIEW, '/');
  }

  clearLocalStorage() {
    localStorage.clear();
    this.commonService.ProjectLSData = {};
  }

  activateSession() {
    return this.http.post(`${this.config.apiUrl}user/session/activate`, { sessionId: this.getSessionId() });
  }
}
