import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService,) { this.config = configService.config; }

  getUserInfo() {
    return this.http.get(`${this.config.apiUrl}user/userinfo`);
  }

  getUserRoles() {
    return this.http.get(`${this.config.apiUrl}user/roles`);
  }

  getAllUsers() {
    return this.http.get(`${this.config.apiUrl}user`);
  }

  getAllRoles() {
    return this.http.get(`${this.config.apiUrl}user/roles/all`);
  }

  editUserState(source: any, emailId: any) {
    if (source == 'Inactive') {
      return this.http.post(`${this.config.apiUrl}user/enable`, emailId);
    } else {
      return this.http.post(`${this.config.apiUrl}user/disable`, emailId);
    }
  }

  createNewUser(userData: any) {
    return this.http.post(`${this.config.apiUrl}user/create`, userData);
  }

  editUser(userData: any) {
    return this.http.post(`${this.config.apiUrl}user/edit`, userData);
  }

  getNotifications() {
    return this.http.get(`${this.config.apiUrl}slide/notification/false`);
  }

  clearNotifications() {
    return this.http.post(`${this.config.apiUrl}slide/notification`, {});
  }

  clearIndvNotification(id: any) {
    return this.http.post(`${this.config.apiUrl}slide/notification/${id}`, {});
  }
}
