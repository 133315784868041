<div class="mainBoardMainDiv">
    <app-header></app-header>
    <div class="mainBoardBody">
        <app-sidebar></app-sidebar>
        <div class="appDynamicScreen">
            <router-outlet></router-outlet>
            <footer>
                <!-- <div class="browserZoomed" *ngIf="isBroswerZoomed">
                    Please reset browser zoom level to 100% for better performance
                </div> -->
                <div>{{copyrightMessage}}</div>
            </footer>
        </div>
    </div>
</div>