import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainBoardRoutingModule } from './main-board-routing.module';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainBoardComponent } from './main-board.component';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '../app-material/app-material.module';
import { GalleryComponent } from '../gallery/gallery.component';
import { SharedModule } from "../shared.module";

@NgModule({
    declarations: [
        MainBoardComponent,
        HeaderComponent,
        SidebarComponent,
        GalleryComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AppMaterialModule,
        MainBoardRoutingModule,
        SharedModule
    ]
})
export class MainBoardModule { }
