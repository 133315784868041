import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCheckboxRequiredValidator } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonService } from 'src/app/common.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class LoginPopupComponent implements OnInit {
  userEmailId: string = '';
  userPassword: string = '';
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  validEmail: any;
  hide: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  invalidUserCredentials: boolean = false;
  currentUser: any = {};
  isChangePassword = false;
  isOtpSend = false;
  isotpSumitted = false;
  isPasswordConfirmed = false;
  isResetPassword: boolean = false;
  appConfig: any;
  isMailsend: boolean = false;
  isForgetPasswordEnabled: boolean = false;
  isCodeVerified: boolean = false;
  resetUser: any;
  hideOldPassword: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  invalidNewUserPassword: boolean = false;
  passwordConstraintMsg: any;
  forgetPasswordObj: any = {
    emailId: '',
    verificationCode: '',
    oldPassword: '',
    newPassword: '',
    confirmedPassword: ''
  };

  resetPassword: any = {
    emailId: '',
    newPassword: '',
    confirmedPassword: ''
  }
  showLoader: boolean = false;
  userEmails = new FormGroup({
    primaryEmail: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
    ),
  });

  constructor(public dialogRef: MatDialogRef<LoginPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private commonService: CommonService,
    private configService: AppConfigService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.appConfig = configService.config;
  }


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      // this.authService.removeTokens();
      let paramsKey: any = Object.keys(params);
      if (paramsKey.length > 0) {
        this.resetPassword.emailId = atob(paramsKey[0]);
        // this.resetUser = Buffer.from(paramsKey[0], 'base64');
        this.isResetPassword = true;
        // alert('Working');
      } else {
        this.isResetPassword = false;
      }
    });
    this.isChangePassword = this.data.changePassword ? this.data.changePassword : false;
    this.passwordConstraintMsg = (this.isChangePassword) ? this.translate.instant("USER_CONFIGURATION.Password_Constraint_Message") : '';
    this.isOtpSend = false;
    this.isotpSumitted = false;
    this.isPasswordConfirmed = false;
    this.currentUser = this.commonService.ProjectLSData?.currentUser;
    if (this.data.isAccessTokenExpired || this.isChangePassword || this.isResetPassword) {
      this.userEmailId = this.currentUser?.email;
    }
  }

  validateLogin() {
    this.invalidUserCredentials = false;
    let userCredentials = {
      "email": this.userEmailId,
      "password": this.userPassword
    };

    this.authService.login(userCredentials).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res == "true") {
        this.userService.getUserInfo().pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          if (res) {
            if (this.data.isAccessTokenExpired) {
              this.dialogRef.close({ loginStatus: "success" });
            } else {
              // localStorage.setItem("currentUser", JSON.stringify(res));
              this.commonService.ProjectLSData["currentUser"] = res;
              this.commonService.setProjectLSData();
              this.authService.storeCurrentUser(this.commonService.ProjectLSData?.currentUser);
              this.userService.getUserRoles().subscribe((res: any) => {
                if (res != "") {
                  let roleArr = [];
                  for (let i = 0; i < res.length; i++) {
                    roleArr.push({
                      role: res[i],
                      roleDisplayText: (res[i] == "APPLICATION_ADMIN") ? 'Application Admin' : 'Technician'
                    });
                  }
                  // localStorage.setItem("userRoleList", JSON.stringify(roleArr));
                  this.commonService.ProjectLSData["userRoleList"] = roleArr;
                  this.commonService.setProjectLSData();
                  this.router.navigate([`/mainboard/${(roleArr[0]?.role == "APPLICATION_ADMIN") ? 'configure' : 'gallery/allimages'}`]);
                  this.dialogRef.close();

                } else {
                  this.authService.logout();
                }
              });
              this.dialogRef.close({ loginStatus: 'success' });
            }

          }
        });
      } else if (res == "resetPassword") {
        this.resetPassword.emailId = this.userEmailId;
        this.isResetPassword = true;
      }
      else {
        this.invalidUserCredentials = true;
      }
    });
  }

  newUserPasswordReset() {
    this.userEmailId = '';
    this.userPassword = '';
    let userData = {
      email: this.resetPassword.emailId,
      password: this.resetPassword.confirmedPassword
    }
    this.authService.resetNewUserPassword(userData).subscribe((response: any) => {
      if (response.success) {
        this.isResetPassword = false;
        this.resetPassword.emailId = '';
        this.resetPassword.newPassword = '';
        this.resetPassword.confirmedPassword = '';
        this.commonService.showToastMsg(response.message, 'success');
        this.router.navigate(['/login']);
      } else {
        this.commonService.showToastMsg(response.message, 'error');
      }
    })
  }

  closeResetPassword() {
    this.isResetPassword = false;
  }

  forgetPassword() {
    this.isChangePassword = true;
  }

  validateEmail() {
    this.isOtpSend = true;
  }

  submitOtp() {
    this.isotpSumitted = true;
  }

  submitPassword() {
    this.isPasswordConfirmed = true;
  }

  reloadOnInit() {
    this.ngOnInit();
  }

  CancelLogin() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }

  enableForgetPassword() {
    this.isForgetPasswordEnabled = true;
  }

  sendMail() {
    // this.isCodeVerified = true;
    this.showLoader = true;
    if (this.userEmails.get('primaryEmail')?.invalid && this.userEmails.get('primaryEmail')?.touched && this.forgetPasswordObj.emailId)
    this.commonService.sendMail(this.forgetPasswordObj.emailId).pipe(finalize(() => this.showLoader = false)).subscribe((res: any) => {
      this.isMailsend = true;
    }, (error: any) => {
      this.showLoader = false;
    });
  }

  verifyCode() {
    this.commonService.verifyCode({
      userId: this.forgetPasswordObj.emailId,
      verificationCode: this.forgetPasswordObj.verificationCode
    }).subscribe((res: any) => {
      if (res.success)
        this.isCodeVerified = true;
    })
  }

  validatePasswordBlur() {
    if (!this.validatePassword(this.forgetPasswordObj.newPassword)) {
      this.invalidNewUserPassword = true;
      return;
    }
    else this.invalidNewUserPassword = false;
  }

  validatePassword(password: String) {
    return String(password)
      .match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{7,}$/
      );
  };

  setNewPassword() {
    let resetPasswordSevice = this.isChangePassword ? this.commonService.changePassword({
      oldPassword: this.forgetPasswordObj.oldPassword,
      newPassword: this.forgetPasswordObj.newPassword
    }) : this.commonService.resetPassword({
      email: this.forgetPasswordObj.emailId,
      password: this.forgetPasswordObj.newPassword
    });
    if (this.forgetPasswordObj.newPassword == this.forgetPasswordObj.confirmedPassword) {
      if (this.forgetPasswordObj.newPassword.length < 8) {
        this.commonService.showToastMsg(this.translate.instant("USER_CONFIGURATION.Min_Password_Length"), 'warn');
        return;
      };
      if (this.forgetPasswordObj.newPassword.length > 16) {
        this.commonService.showToastMsg(this.translate.instant("USER_CONFIGURATION.Max_Password_Length"), 'warn');
        return;
      }
      resetPasswordSevice.subscribe((res: any) => {
        if (res.success) {
          this.isForgetPasswordEnabled = this.isMailsend = this.isCodeVerified = false;
          this.commonService.showToastMsg(res.message, 'success');
          if (this.isChangePassword) {
            this.dialogRef.close();
          } else {
            this.closeForgetPassword();
          }
          this.forgetPasswordObj = {
            emailId: '',
            verificationCode: '',
            oldPassword: '',
            newPassword: '',
            confirmedPassword: ''
          }
        }
      }, (err: any) => {
        // this.commonService.showToastMsg(err.message);
      }
      )
    } else {
      this.commonService.showToastMsg(this.translate.instant("USER_CONFIGURATION.New_Password_Match_Error"), 'warn');
    }

  }

  closeForgetPassword() {
    this.isForgetPasswordEnabled = this.isMailsend = this.isCodeVerified = false;
    this.forgetPasswordObj = {
      emailId: '',
      verificationCode: '',
      newPassword: '',
      confirmedPassword: ''
    };
    this.userEmails.reset();
  }

  CancelChangePassword() {
    this.forgetPasswordObj = {
      emailId: '',
      verificationCode: '',
      newPassword: '',
      confirmedPassword: ''
    };
    this.dialogRef.close();
  }

  checkEmail() {
    if (this.emailControl.valid) {
      this.validEmail = true;
    } else {
      this.validEmail = false;
    }
  }

}

