import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';
import { AppConfigService } from 'src/app/app-config.service';
import { CommonService } from 'src/app/common.service';
import { UserService } from 'src/app/services/user.service';
import { LoginPopupComponent } from 'src/app/login/login-popup/login-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfigureService } from 'src/app/configuration/configure.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  isBroswerZoomed: boolean = false;
  systemZoomed: boolean = false;
  zoomWarningMsg: String = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkBrowserZoom();
  }
  @HostListener('window:focus', ['$event'])
  onSystemResize(event: any) {
    this.checkBrowserZoom();
  }
  firstName: String = '';
  lastName: String = '';
  currentUser: any;
  initials: String = '';
  activeRole: String = '';
  circleColor: String = '';
  notifications: any[] = [];
  colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];


  dummyNotifications: any = [
    {
      "id": 87,
      "message": "Edward Miller has shared PROJECT testNotification with you",
      "createdAt": "2024-02-06T10:04:53Z",
      "sendBy": "edward.miller@airamatrix.com",
      "read": false
    },
    {
      "id": 86,
      "message": "Edward Miller has shared PROJECT Barcode Images with you",
      "createdAt": "2024-02-06T10:03:02Z",
      "sendBy": "edward.miller@airamatrix.com",
      "read": false
    },
    {
      "id": 84,
      "message": "Algorithm NEPC Identification analysis Completed for Control06.ndpi",
      "createdAt": "2024-02-06T06:14:18Z",
      "sendBy": "SYSTEM",
      "read": false
    },
    {
      "id": 83,
      "message": "Algorithm Liver analysis Completed for Control06.ndpi",
      "createdAt": "2024-02-06T06:08:23Z",
      "sendBy": "SYSTEM",
      "read": false
    }
  ]

  userRoleArr: any = [];
  selectedUserRole: any = "";
  appConfig: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    public authService: AuthService,
    private configService: AppConfigService,
    private commonService: CommonService,
    private userService: UserService,
    public translate: TranslateService,
    private configureService: ConfigureService
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.appConfig = configService.config;
  }

  ngOnInit(): void {
    const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
    this.circleColor = this.colors[randomIndex];
    this.activeRole = this.commonService.ProjectLSData?.userActiveRole;
    this.userRoleArr = this.commonService.ProjectLSData?.userRoleList || '{}';
    this.selectedUserRole = (this.activeRole) ? this.activeRole : this.userRoleArr[0]?.role;
    this.currentUser = this.commonService.ProjectLSData?.currentUser;
    this.firstName = this.currentUser?.firstName ? this.currentUser?.firstName : "";
    this.lastName = this.currentUser?.lastName ? this.currentUser?.lastName : "";
    this.initials = ((this.firstName?.charAt(0) || '') + (this.lastName?.charAt(0) || '')).toUpperCase();
    this.commonService.changeRoleId(this.selectedUserRole);
    this.checkBrowserZoom();
    this.configureService.getConfigData().subscribe((result: any) => {
      if (result.success) {
        this.commonService.ProjectLSData.qcProcess = result.data?.qcMode;
        this.commonService.setProjectLSData();
      }
    });
    // this.getStatusNotification();
  }

  checkBrowserZoom() {
    let zoom = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    this.isBroswerZoomed = false;
    if ((zoom < 95) || (zoom > 105)) {
      this.isBroswerZoomed = true;
    }
    this.systemZoomed = (window.devicePixelRatio != 1);

    this.setZoomWarningMsg();
  }

  setZoomWarningMsg() {
    if (this.isBroswerZoomed && this.systemZoomed) {
      this.zoomWarningMsg = 'Please reset system display scale value & browser zoom level to 100% for better UI experience';
    } else if (this.isBroswerZoomed) {
      this.zoomWarningMsg = 'Please reset browser zoom level to 100% for better UI experience';
    } else if (this.systemZoomed) {
      this.zoomWarningMsg = 'Please set system display scale value to 100% for better UI experience';
    } else {
      this.zoomWarningMsg = '';
    }
  }

  changeRole() {
    this.commonService.setCurrentSource('');
    delete this.commonService.ProjectLSData?.currentSelectedSource;
    delete this.commonService.ProjectLSData?.activeFolder;
    delete this.commonService.ProjectLSData?.childFolder;
    delete this.commonService.ProjectLSData?.userActivePlace;
    delete this.commonService.ProjectLSData?.selectionLevel;
    delete this.commonService.ProjectLSData?.activeGalleryTab;
    delete this.commonService.ProjectLSData?.artefactFilter;
    this.commonService.setProjectLSData();
    this.commonService.changeRoleId(this.selectedUserRole);
  }



  logout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.panelClass = "confirmDialog";
    dialogConfig.data = {
      headerTitle: "Logout",
      confirmMsg: this.translate.instant("USER_CONFIGURATION.Logout_Confirmation"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      confirmActionData: {
        currentPage: 'header',
        logoutAction: this.navToLogin,
        that: this
      },
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
  }

  navToLogin(dialogRef: any, that: any) {
    // that.router.navigate(['']);
    // localStorage.clear();
    if (that.router.url.indexOf('/dashboard') > -1) {
      that.router.navigate(['/login']);
      return;
    }
    that.authService.logout().subscribe((response: any) => {
      that.router.navigate(['/login']);
      if (that.commonService?.ProjectLSData) {
        that.commonService.ProjectLSData = {};
      }
      // that.commonService.showToastMsg(response.message, 'error');
      dialogRef.close();
    })
  }

  changePassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "20%";
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Save",
      changePassword: true,
      isAccessTokenExpired: false
    };
    const dialogref = this.dialog.open(LoginPopupComponent, dialogConfig);
  }

  showDashboardSave() {
    if (this.router.url.indexOf('/dashboard') > -1 && this.commonService.dashboardUserPrefChanged) {
      this.commonService.sendDashboardSaveEvent(true)
    }
  }

  getStatusNotification() {
    this.notifications = [];
    this.userService.getNotifications().subscribe((result: any) => {
      if (result.success) {
        if (result.data.length) {
          this.notifications = result.data;
        } else {
          this.notifications = this.dummyNotifications
        }
        console.log('Notification', this.notifications);
        setTimeout(() => {
          // this.getStatusNotification();
        }, 5000);
      }
    }, (err: any) => {
      if (err.status != 400) {
        // this.getStatusNotification();
      }
    });
  }


  markIndvAsRead(id: any) {

  }

  markAllAsRead() {

  }

}