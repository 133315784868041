export enum Icons {
  logo = "airaLogo",
  selectedBG = "selectedSideIconBG",
  userProfile = "user-profile",
  checkCircle = "checkCircle",
  closeCircle = "closeCircle",
  dashboardIcon = "sideBar/dashboard_5_2",
  galleryIcon = "sideBar/gallerySvg",
  uploadIcon = 'sideBar/imageRepository',
  usersIcon = "sideBar/users",
  configureIcon = "sideBar/config_trail",
  eyeCheck = "eye_grey",
  activeEyeCheck = "orange_eye",
  imgIcon = "img",
  gridViewIcon = "grid_final",
  listViewIcon = "list_final_1",
  folderIconSvg = "Gallery_folder",
  viewReportIcon = "file-sync-outline",
  galleryItemPreviewIcon = "preview",
  galleryItemMalignantIcon = "malignant",
  galleryItemBeignalIcon = "beignal",
  galleryItemReviewedIcon = "reviewed",
  galleryItemNotReviewedIcon = "not_reviewed",
  galleryItemRejectedImageIcon = "img_reject",
  imageAccepted = "accepted_check_1",
  imageRejected = "rejected_1",
  notification = "notification",
  maximizeImage = "maximize_grey",
  slidePreviewIcon = "Image Preview Icon-Active",
  labelPreviewIcon = "Slide Label Icon-Active",
  logoutIcon = "logoutIcon",
  noScannerSelectedIcon = "no_data_to_display",
  imageSvgIcon = "img_grey",
  imageRescan = "rs_1",
  imageReprocess = "rp_1",
  selectedSource = "scanner_orange",
  downloadReport = "downloadReport",
  analysisIcon = 'sideBar/new_ana_1',
  accessibleIcon = "accessible",
  notaccessibleIcon = "not_accessible",
  triggerAnalysisIcon = "ana_trig_active",
  resetIcon = "reset",
  moveImageIcon = "moveImageIcon",
  dashboardMenu = 'menu_grey',
  dashboardChartMenu = '3dot_menu_grey',
  dashboardCalendar = 'calandar_grey',
  searchInactiveIcon = 'SEARCH_GREY',
  searchActiveIcon = 'search_active',
  barChart = 'bar_chart',
  lineChart = 'line_chart',
  pieChart = 'pie_chart',
  doughnutChart = 'doughnut_chart',
  tileChart = 'tile_chart',
  radarChart = 'radar_chart',
  polarChart = 'polar_chart',
  downloadReport2 = "downloadReport2",
  saveSettings = 'save_settings',
  saveOrange = 'save_settings_orange',
  filterIcon = 'filter',
  editSvgIcon = 'edit_orange',
  closeSvgIcon = 'close_grey',
  reviewedSvgIcon = 'reviewed',
  analysisFailed = 'failed',
  queuedForAnalysis = 'loading_1',
  fileWatcher = 'filewatcher',
  tissueNotFound = 'tissuenotfound',
  zoomReset = 'zoomReset',
  zoomOut = 'zoomOut',
  zoomIn = 'zoomIn',
  corruptImg = 'img_reject',
  previewNext = 'next_svg',
  previewPrevious = 'prev_svg',
  onlineFilewatcher = 'Online_Filewatcher',
  offlineFilewatcher = 'Offline_Filewatcher'
}