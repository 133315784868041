import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from '../common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class UploadDialogComponent implements OnInit {
  newlyAddedFiles: any = [];
  appConfig: any;
  imageFormats: any = [];
  constructor(public dialogRef: MatDialogRef<UploadDialogComponent>, public commonService: CommonService, @Inject(MAT_DIALOG_DATA) public data: any, private configService: AppConfigService) {
    this.appConfig = this.configService.config;
  }
  ngOnInit(): void {
    this.imageFormats = this.appConfig.imageFormatsForUpload
  }


  onSelect(event: any) {
    this.newlyAddedFiles = [];
    this.newlyAddedFiles = [...event.addedFiles];
    let newFileNames = this.newlyAddedFiles.map((indvFile: any) => {
      return indvFile.name;
    });
    // for (let file of this.commonService.finalImgFiles) {
    //   for (let newFile of this.newlyAddedFiles) {
    //     newFile.alreadySelected = newFile.name == file.name;
    //   }
    // }

    // for (let newFile of this.newlyAddedFiles) {
    //   newFile.alreadySelected = false;
    //   for (let file of this.commonService.finalImgFiles) {
    //     if (newFile.name == file.name) {
    //       newFile.alreadySelected = true;
    //       break;
    //     }
    //   }
    // }

    this.commonService.checkImagesForUpload(newFileNames).subscribe((response: any) => {
      if (response.success) {
        for (let newFile of this.newlyAddedFiles) {
          newFile.alreadySelected = false;
          for (let file of this.commonService.finalImgFiles) {
            if (newFile.name == file.name) {
              newFile.alreadySelected = true;
              break;
            }
          }
        }
      }
      for (let x = 0; x < this.newlyAddedFiles.length; x++) {
        if (!this.newlyAddedFiles[x].alreadySelected) {
          this.newlyAddedFiles[x].isFileExist = (response.data.indexOf(this.newlyAddedFiles[x].name) >= 0);
          if (this.newlyAddedFiles[x].isFileExist && !this.newlyAddedFiles[x].alreadySelected) {
            this.newlyAddedFiles[x].progressValue = 0;
            this.newlyAddedFiles[x].isFileUploadCompleted = false;
            this.newlyAddedFiles[x].completeStatus = false;
          }
        }
      }
      let currentFileLength = this.commonService.finalImgFiles?.length;
      this.commonService.finalImgFiles = [...this.commonService.finalImgFiles, ...this.newlyAddedFiles];
      this.newlyAddedFiles = [];
      if (currentFileLength == this.commonService.uploadIndex) {
        this.commonService.uploadImage(this.data.reloadList.bind(this));
      }
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
