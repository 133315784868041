<div class="mainDialogDiv">
    <div class="dialogHeader">
        Upload Images
    </div>
    <div class="dialogBody">
        <form #fileUpload="ngForm">
            <ngx-dropzone (change)="onSelect($event)" class="dropzone" accept={{imageFormats}}>
                <ngx-dropzone-label style="outline: none;">
                    <i class="h1 uil uil-cloud-upload fs-50 text-muted dripicons-cloud-upload"></i>
                    <h3>Drop file here or Browse</h3>
                </ngx-dropzone-label>
            </ngx-dropzone>
            <div class="uploadWrapper">
                <div class="indvImageUploadDiv" *ngFor="let indvFile of commonService.finalImgFiles; let i = index">
                    <mat-card class="card">
                        <div class="card-body">
                            <div class="idvImageTitle">
                                <p class="card-text" title={{indvFile.name}}>
                                    {{indvFile.name}}</p>
                                <small class="uploadWaitText"
                                    *ngIf="!indvFile.completeStatus && indvFile.progressValue == 100">Please
                                    wait</small>
                                <small class="warning" *ngIf="!indvFile.completeStatus  && indvFile.slideId == ''">Error
                                    while uploading</small>
                                <small class="uploadSuccessText"
                                    *ngIf="indvFile.completeStatus && indvFile.progressValue == 100"
                                    class="completedText">Completed</small>
                                <small class="warning" *ngIf="!indvFile.isFileExist">Image already exists.</small>
                            </div>

                            <div class="progressBarDiv">
                                <mat-progress-bar mode="determinate" role="progressbar" aria-valuemin="0"
                                    aria-valuemax="100" [value]="indvFile.progressValue"
                                    [ngClass]="(indvFile.progressValue == 100)?'uploadComplete':''">
                                    {{indvFile.progressValue}}%
                                </mat-progress-bar>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </form>
        <div class="buttonClass actions dialog-actions modal-footer">
            <button mat-flat-button class="cancelBtn" (click)="onNoClick()">Close</button>
        </div>
    </div>
</div>