
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoginPopupComponent } from '../login/login-popup/login-popup.component';
import { CommonService } from '../common.service';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: AuthService, private commonService: CommonService,
        private dialog: MatDialog, private snackBar: MatSnackBar) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /* request = this.setHeader(request);
        if ((request.url.indexOf("user/login") < 0)) {
            if (this.authService.getAccessToken()) {
                request = this.addToken(request, this.authService.getAccessToken());
            }
        } */
        if ((request.url.indexOf("user/login") > 0)) {
            request = this.setHeader(request);
        }
        else if (!request.url.includes('/schema.json') ||
            !request.url.includes('public-access/') ||
            !request.url.includes('forgetUserPassword?userId') ||
            !request.url.includes('verifyCode') ||
            !request.url.includes('resetPassword') ||
            !request.url.includes('session/config')) {
            if (this.authService.getAccessToken()) {
                request = this.addToken(request, this.authService.getAccessToken());
            } else {
                request = this.setHeader(request);
            }
        }
        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && error.status == 401) {
                if ((request.url.indexOf('/user/login') < 0) && !request.url.includes("user/logout")) {
                    return this.handle401Error(request, next);
                }
            } else if (error instanceof HttpErrorResponse && error.status == 400 && request.url.includes('/user/logout')) {
                return throwError(error);
            } else {
                if (request.url.indexOf('/token/refresh') != -1) {
                    this.isRefreshing = false;
                }
                if (error instanceof HttpErrorResponse && ((error.status === 500 && !(request.url.includes("/schema?query"))) || (error.status === 409) || ((error.status === 400) && !(request.url.includes("/token/refresh"))))) {
                    if (error?.error?.message) {
                        this.commonService.showToastMsg(error.error.message, 'error')
                    }

                }
            }
            return throwError(error);
        }));
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    private setHeader(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                'Content-Type': 'application/json; ',
                // 'aira_am_access_token': '}z4Y7b-vgUdZ9d`7',
                // 'Authorization': localStorage['sessionToken'] ? localStorage['sessionToken'] : ''
            }
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.authService.refreshToken().pipe(
                switchMap((token: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.accessToken);
                    return next.handle(this.addToken(request, token.accessToken));
                }),
                catchError((err: any) => {
                    this.isRefreshing = false;
                    if (err.status === 400) {
                        const dialogConfig = new MatDialogConfig();
                        // dialogConfig.width = "20%";
                        dialogConfig.disableClose = true;
                        dialogConfig.panelClass = 'tokenExpLoginPopupPanel';
                        dialogConfig.backdropClass = 'clsLoginPopupBackDrop';
                        dialogConfig.data = {
                            headerTitle: "Login",
                            confirmMsg: "Session expired. Login again to continue",
                            cancelButtonText: "Cancel",
                            confirmButtonText: "Yes",
                            isAccessTokenExpired: true
                        };
                        const dialogref = this.dialog.open(LoginPopupComponent, dialogConfig);
                        return dialogref.afterClosed().pipe(switchMap((result: any) => {
                            if (result && result.loginStatus == "success") {
                                this.refreshTokenSubject.next(this.authService.getAccessToken());
                                return next.handle(this.addToken(request, this.authService.getAccessToken()));
                            }
                            return throwError(err);
                        }));
                    }
                    return throwError(err);
                }));
        } else {
            return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(access_token => {
                    return next.handle(this.addToken(request, access_token));
                }));
        }
    }
}