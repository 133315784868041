import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../app-config.service';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe extends
    DatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) locale: string, private appConfig: AppConfigService) {
        super(locale);
    }
    override transform(value: any, args?: any): any {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return super.transform(value, (args == 'onlyDate') ? this.appConfig.config.dateFormat : this.appConfig.config.dateTimeFormat, timeZone);
    }
}