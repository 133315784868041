<div class="customSnackBar" [ngClass]="data.mainClass" matSnackBarLabel>
    <div class="iconDiv">
        <mat-icon *ngIf="data.type == 'success'">check</mat-icon>
        <mat-icon *ngIf="data.type == 'info'">info</mat-icon>
        <mat-icon *ngIf="data.type == 'warn'">warning</mat-icon>
        <mat-icon *ngIf="data.type == 'error'">block</mat-icon>
    </div>
    <div class="snackBarMsg">
        {{data.message}}
    </div>
</div>