import { Component, Inject } from '@angular/core';
import { IconService } from './icons-setting/Icons.service';
import { CommonService } from './common.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppConfigService } from './app-config.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import * as moment from 'moment';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-yyyy',
  },
  display: {
    dateInput: 'MM-DD-yyyy',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AppComponent {
  title = this.commonService.projectName;

  constructor(@Inject(MAT_DATE_FORMATS) public dateFormat: any,
    private iconService: IconService,
    public commonService: CommonService,
    private dateAdapter: DateAdapter<any>,
    private configService: AppConfigService) {
    dateFormat.parse.dateInput = configService.config.dateFormat.toUpperCase();
    dateFormat.display.dateInput = configService.config.dateFormat.toUpperCase();
    dateFormat.display.monthYearLabel = configService.config.dateFormat.toUpperCase();
    dateFormat.display.monthYearA11yLabel = configService.config.dateFormat.toUpperCase();
    this.iconService.registerIcons()
  }
}
