import { NgModule } from '@angular/core';
import { CustomDatePipe } from './common/custom.datepipe';
import { NumberSuffixPipe } from './common/number-suffix.pipe';

@NgModule({
    declarations: [CustomDatePipe,
        NumberSuffixPipe],
    // exports is required so you can access your component/pipe in other modules
    exports: [CustomDatePipe, NumberSuffixPipe]
})
export class SharedModule { }