import { Component, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from '../common.service';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';

@Component({
  selector: 'app-upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class UploadListComponent {
  appConfig: any;
  uploadList: any = [];
  displayedColumns: any = [{
    title: "Image Name",
    key: 'imageName'
  }, {
    title: "Date Modified",
    key: 'dateModified'
  }, {
    title: "Date Scanned",
    key: 'dateScanned'
  }, {
    title: "Location",
    key: 'physicalLoc'
  },
  ]
  columnsToDisplay: any = ['imageName', "dateScanned", "physicalLoc"]
  constructor(public commonService: CommonService, public dialog: MatDialog, public translate: TranslateService, public appConfigService: AppConfigService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.appConfig = appConfigService.config;

  }
  ngOnInit(): void {
    this.getUploadList();
  }

  getUploadList() {
    this.commonService.getUploadedImages().subscribe((response: any) => {
      if (response.success) {
        this.uploadList = response.data;
      }
    });
  }

  openUploadDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "confirmDialog";
    dialogConfig.data = {
      reloadList: this.getUploadList.bind(this),
    }
    const dialogref = this.dialog.open(UploadDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe((result: any) => { });
  }
}
