import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from './common/custom-snackbar/custom-snackbar.component';
import { StateChange } from 'ng-lazyload-image';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  appConfig: any = {};
  ProjectLSData: any = {};
  resultType: string = 'manual';
  // userRoleList = JSON.parse(localStorage.getItem('userRoleList') || '{}');
  userRoleList = this.ProjectLSData?.userRoleList || '{}';
  defaultRole = this.userRoleList ? this.userRoleList?.length ? this.userRoleList[0] : '' : '';
  @Output() openSelectEvent = new EventEmitter<string>();
  @Output() onRepoSearchClick = new EventEmitter<string>();
  @Output() onIndvSearchClick = new EventEmitter<string>();
  @Output() onFolderNavigation = new EventEmitter<string>();
  selectedSource: any;
  projectName: string = '';
  hideDownload: boolean = false;
  // galleryRefresh: any;


  private currentRoleSource = new BehaviorSubject(this.defaultRole.role);
  currentRole = this.currentRoleSource.asObservable();

  private selectedLocation = new BehaviorSubject('');
  currentPlace = this.selectedLocation.asObservable();

  private selectedScannerSource = new BehaviorSubject({});
  currentSource = this.selectedScannerSource.asObservable();

  // private selectedArtefact = new BehaviorSubject('');
  // artefact = this.selectedArtefact.asObservable();


  private galleryRefresh = new BehaviorSubject('');
  autoRefresh = this.galleryRefresh.asObservable();

  preChangeIconState: boolean = false;
  galleryPreview: any = '';
  totalProcessedImgs: number = 0;
  totalUnprocessedImgs: number = 0;
  totalSelectedImgs: number = 0;
  dashboardUserPrefChanged: boolean = false;

  public finalImgFiles: any = [];
  public uploadIndex: any = 0;

  public browserTimeZone: any;

  public showDashboardSave = new BehaviorSubject(false);
  showDashboardSaveEvent = this.showDashboardSave.asObservable();

  constructor(private http: HttpClient, private configService: AppConfigService, private snackBar: MatSnackBar) {
    this.appConfig = configService.config;
    this.projectName = this.appConfig.projectName;
    this.fetchProjectLSData();
    if (this.ProjectLSData?.currentSelectedSource) {
      this.setCurrentSource(this.ProjectLSData?.currentSelectedSource);
    }
    if (this.ProjectLSData?.galleryPreview) {
      this.galleryPreview = this.ProjectLSData?.galleryPreview;
      this.setGalleryPreview(this.ProjectLSData?.galleryPreview);
    } else {
      this.galleryPreview = 'Grid';
      this.setGalleryPreview('Grid');
    }

    this.browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  setGalleryPreview(preview: any) {
    this.ProjectLSData["galleryPreview"] = preview;
    this.setProjectLSData();
  }

  fetchProjectLSData() {
    if (localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
  }

  setCurrentPlace(place: string) {
    this.ProjectLSData["userActivePlace"] = place;
    this.setProjectLSData();
    this.selectedLocation.next(place);
  }

  refreshGalleryCall(flag: any) {
    this.ProjectLSData["refreshGallery"] = flag;
    this.setProjectLSData();
    this.galleryRefresh.next(flag);
  }

  setCurrentSource(source: any) {
    if (source) {
      this.ProjectLSData["currentSelectedSource"] = source;
      this.setProjectLSData();
    }
    this.selectedScannerSource.next(source);
  }

  getUploadedImages() {
    return this.http.get(`${this.appConfig.apiUrl}slide/SlideList?imageSource=upload`);
  }

  checkImagesForUpload(imageNames: any) {
    let imageFile = imageNames;
    let key = "imageFile";
    let imagesData = {
      [key]: imageFile
    };
    return this.http.post(`${this.appConfig.apiUrl}slide/uploadcheck`, imagesData);
  }

  uploadImages(images: any): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.appConfig.apiUrl}slide/upload`, images, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  uploadImage(reloadList: any) {
    if (this.uploadIndex < this.finalImgFiles.length) {
      if (!this.finalImgFiles[this.uploadIndex].isFileExist || this.finalImgFiles[this.uploadIndex].alreadySelected) {
        this.uploadIndex += 1;
        this.uploadImage(reloadList);
        return;
      }
      const frmData = new FormData();
      frmData.append("imageFile", this.finalImgFiles[this.uploadIndex]);
      frmData.append("imageSource", 'upload');

      this.finalImgFiles[this.uploadIndex].inprogress = true;
      this.uploadImages(frmData).subscribe((item: any) => {
        if (item.type === HttpEventType.UploadProgress) {
          this.finalImgFiles[this.uploadIndex].progressValue = Math.round(100 * item.loaded / item.total);
        }
        else {
          if (item instanceof HttpResponse) {
            if (item.status == 200) {
              this.finalImgFiles[this.uploadIndex].completeStatus = true;
              this.finalImgFiles[this.uploadIndex].fileId = item.body.data[0]?.imageId;
              this.finalImgFiles[this.uploadIndex].isFileUploadCompleted = true;
            }
            if (reloadList) {
              reloadList('upload');
            }
            this.uploadIndex += 1;
            this.uploadImage(reloadList);
          }
        }
      }, (err: any) => {
        this.finalImgFiles[this.uploadIndex].progressValue = 0;
        this.finalImgFiles[this.uploadIndex].completeStatus = false;
        this.finalImgFiles[this.uploadIndex].slideId = '';
        this.finalImgFiles[this.uploadIndex].isFileUploadCompleted = false;
        this.uploadIndex += 1;
        this.uploadImage(reloadList);
      })
    }
  }

  login(userCredentials: any) {
    return this.http.post(`${this.appConfig.apiUrl}user/login`, userCredentials);
  }

  changeRoleId(role: any) {
    this.ProjectLSData["userActiveRole"] = role;
    this.setProjectLSData();
    this.currentRoleSource.next(role);
  }

  createUser(newUserData: any) {
    return this.http.post(`${this.appConfig.apiUrl}user/create-user`, newUserData);
  }

  getRolePlaces(role: any) {
    return this.http.get(`${this.appConfig.apiUrl}user/roles/${role}/places`);
  }

  showToastMsg(message: any, type: any = 'success', duration = 3000) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message: message,
        type: type,
        mainClass: (type == 'error') ? 'errorToast' : (type == 'warn') ? 'warningToast' : (type == 'info') ? 'infoToast' : 'successToast'
      },
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: 'customToast'
    });
  }

  sendMail(emailId: any) {
    // http://{{Host}}:9070/qc/user/forgetUserPassword?email=onkar.yogi@airamatrix.com  
    return this.http.get(`${this.appConfig.apiUrl}user/forgetUserPassword?email=${emailId}`);
  }

  verifyCode(requestBody: any) {
    http://{{Host}}:9070/qc/user/verifyCode
    return this.http.post(`${this.appConfig.apiUrl}user/verifyCode`, requestBody);
  }

  resetPassword(requestBody: any) {
    // http://{{Host}}:9070/qc/user/resetPassword
    return this.http.post(`${this.appConfig.apiUrl}user/resetPassword`, requestBody);
  }

  downloadReport(requestBody: any, type: any) {
    if (type === 'source') {
      return this.http.post(`${this.appConfig.apiUrl}slide/scanner/artifactResultReportFromSource`, requestBody, { responseType: 'blob', reportProgress: true, observe: 'events' });
    } else {
      return this.http.post(`${this.appConfig.apiUrl}slide/scanner/artifactResultReport`, requestBody, { responseType: 'blob', reportProgress: true, observe: 'events' });
    }
  }

  changePassword(requestBody: any) {
    return this.http.post(`${this.appConfig.apiUrl}user/changePassword`, requestBody);
  }

  setProjectLSData() {
    localStorage[this.projectName] = JSON.stringify(this.ProjectLSData);
  }

  getJobList(paramOne?: any, paramTwo?: any) {
    if (paramOne && paramTwo) {
      return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus?scannerId=${paramOne}&status=${paramTwo}`);
    } else if (paramOne) {
      return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus?scannerId=${paramOne}`);
    } else if (paramTwo) {
      return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus?status=${paramTwo}`);
    } else {
      return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus`);
    }
  }

  getJobsList(queryData: any) {
    return this.http.post(`${this.appConfig.apiUrl}slide/jobStatus`, queryData);
  }

  editBatchName(newBatchName: any, batchId: any) {
    let queryData = { name: newBatchName };
    return this.http.put(`${this.appConfig.apiUrl}slide/jobStatus/${batchId}`, queryData);
  }

  getbatchDetail(id: any) {
    return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus/batch/${id}/jobs`);
  }

  getJobDetail(id: any) {
    return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus/job/${id}/detail`);
  }
  // getfilteredJobList(scannerId:any, status:any){
  //   return this.http.get(`${this.appConfig.apiUrl}slide/jobStatus/scannerId=${scannerId}&&stutus=$status=${status}`);
  // }

  downloadJobDetail(jobId: any, fileType: any) {
    let queryData = {
      fileType: fileType,
      dateTimeFormat: this.appConfig.dateTimeFormat,
      zoneId: this.browserTimeZone
    }
    return this.http.post(`${this.appConfig.apiUrl}slide/jobStatus/job/${jobId}/detail/download`, queryData, { responseType: 'blob', reportProgress: true, observe: 'events' });
  }

  downloadBatchFile(batchId: any, type: any) {
    let queryData = {
      fileType: type,
      dateTimeFormat: this.appConfig.dateTimeFormat,
      zoneId: this.browserTimeZone
    }
    return this.http.post(`${this.appConfig.apiUrl}slide/jobStatus/${batchId}/download`, queryData, { responseType: 'blob', reportProgress: true, observe: 'events' });
  }

  checkBrowserZoom() {
    let zoom = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    let isBrowserZoomed: boolean = false;
    if ((zoom < 95) || (zoom > 105)) {
      isBrowserZoomed = true;
    }
    return isBrowserZoomed;
  }

  vwTOpx(value: any) {
    value = value.replace('vw', '');
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    var result = (x * value) / 100;
    return result;
  }

  getArtifactReportData(reportId: any) {
    return this.http.post(`${this.appConfig.apiUrl}slide/scanner/viewArtifactResultReport`, [reportId]);
  }

  sendDashboardSaveEvent(val: boolean) {
    this.showDashboardSave.next(val);
  }

  reloadFailedLazyLoadImg(event: StateChange, slideData: any, refEl: any, pathVar: any) {
    if (event.reason == 'loading-succeeded') {
      refEl.classList.remove("ng-failed-lazyloaded");
    } else if (event.reason == 'loading-failed') {
      if (this.appConfig.failedLazyloadInfo) {
        if (slideData.retryAttempted < this.appConfig.failedLazyloadInfo.maxRetryAttempt) {
          setTimeout(() => {
            var timestamp = new Date().getTime();
            let imgPathVar = slideData[pathVar].split('?')[0];
            slideData[pathVar] = imgPathVar + '?t=' + timestamp;
            slideData.retryAttempted++;
          }, this.appConfig.failedLazyloadInfo.retryDelay);
        }
      }
    }
  }
}


