import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { ConfigureService } from 'src/app/configuration/configure.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  places: any = [
    // { moduleName: 'gallery', displayName: 'Gallery', isVisible: true },
    // { moduleName: 'dashboard', displayName: 'Dashboard', isVisible: true }
  ];
  selectedRole: any;
  currentModule: any;
  viewerOpenPlace: any;
  selectedPlace: string = '';

  constructor(public router: Router, private commonService: CommonService, private configureService: ConfigureService
  ) { }

  ngOnInit(): void {
    this.commonService.currentRole.subscribe(role => {
      if (role !== this.selectedRole) {
        this.selectedRole = role;
        this.commonService.getRolePlaces(this.selectedRole).subscribe((result: any) => {
          if (result.data.places.length) {
            this.places = [];
            result.data.places.forEach((item: any) => {
              this.places.push({
                'displayName': item,
                'moduleName': item == "Analysis" ? "analysis" : item.toLowerCase(),
                'isVisible': true
              });
            });
            if (!this.commonService.ProjectLSData.userActivePlace) {
              this.router.navigate([`/mainboard/${(this.selectedRole == "APPLICATION_ADMIN") ? 'configure' : 'gallery/allimages'}`]);
              this.commonService.ProjectLSData.userActivePlace = this.selectedPlace = (this.selectedRole == "APPLICATION_ADMIN") ? 'configure' : 'gallery';
              this.commonService.setProjectLSData();
            } else {
              this.selectedPlace = this.commonService.ProjectLSData.userActivePlace;
            }
          }
        });
      }
    });
  }

  changePlace(place: any) {
    if (place == 'configure') {
      this.router.navigate(['mainboard/' + 'configure']);
    } else if (place == 'users') {
      this.router.navigate(['mainboard/' + 'users']);
    } else if (place == 'gallery') {
      this.configureService.getConfigData().subscribe((result: any) => {
        if (result.success) {
          this.commonService.ProjectLSData.qcProcess = result.data?.qcMode;
          this.commonService.setProjectLSData();
        }
      });
      this.router.navigate(['mainboard/' + 'gallery/' + this.commonService.ProjectLSData?.activeGalleryTab]);
    } else if (place == 'dashboard') {
      this.router.navigate(['mainboard/' + 'dashboard']);
    } else if (place == 'analysis') {
      this.router.navigate(['mainboard/' + 'analysis']);
    } else if (place == 'upload') {
      this.router.navigate(['mainboard/' + 'upload']);
    }
    this.selectedPlace = place;
    this.commonService.setCurrentPlace(this.selectedPlace);
  }

  getCurrentPlace() {
    this.commonService.currentPlace.subscribe((currentPlace: any) => {
      this.selectedPlace = currentPlace;
    })
  }

}
